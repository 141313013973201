import { TrackDoc } from '@livekatsomo/models';
import { getOrCreateMethodStore } from '@livekatsomo/suspense';
import { TrackContext } from '@livekatsomo/web/contexts';
import {
  changeTrackMode,
  getTrack,
  updateTrack,
  updateTrackLayout,
  updateTrackTheme,
  uploadTrackPoster,
  validateTrackSlug,
} from '@livekatsomo/web/data';
import { useEvent } from '@livekatsomo/web/data-hooks';

export type TrackProviderProps = {
  children: JSX.Element;
  initialState?: TrackDoc;
  id?: string;
};

export function TrackProvider({
  children,
  id,
  initialState,
}: TrackProviderProps) {
  const { event } = useEvent();
  const trackId = id || event?.tracks?.[0];
  if (!event) throw new Error('This event is no longer available');
  if (!trackId) {
    console.log('TrackProvider: no trackId');
    return null;
  }

  const options: Parameters<typeof getTrack>[0] = {
    eventId: event.id,
    trackId,
  };

  const store = getOrCreateMethodStore(getTrack, options, initialState);

  return (
    <TrackContext.Provider
      value={{
        store,
        uploadTrackPoster,
        updateTrack: (trackFormValues, setProgress) =>
          updateTrack(event.id, trackId, trackFormValues, setProgress),
        updateTrackTheme,
        updateTrackLayout: (layout) =>
          updateTrackLayout(event.id, trackId, layout),
        validateTrackSlug,
        changeTrackMode: (mode) => changeTrackMode(event.id, trackId, mode),
      }}
    >
      {children}
    </TrackContext.Provider>
  );
}
